import {Component, ViewEncapsulation} from '@angular/core';
import {ActivationEnd, Router} from "@angular/router";
import {Location} from "@angular/common";

import {TranslateService} from "@ngx-translate/core";
import {NgbOffcanvas} from "@ng-bootstrap/ng-bootstrap";
import {CookieService} from "ngx-cookie-service";
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {v4 as uuidv4} from 'uuid';

import {AbstractSubscriber} from "./services/abstract-subscriber";
import {COUNTRY_CACHE_NAME, LocaleService} from "./services/locale.service";
import {ConsentService} from "./services/consent.service";
import {environment} from "../environments/environment";

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class MainLayoutComponent extends AbstractSubscriber {
  protected currentYear = 2024;
  protected showChooseLocale = false;
  protected showAcceptCookies = false;
  protected showAcceptCookiesDetails = false;
  protected showPrivacySettings = false;
  protected showPrivacySettingsDetails = false;
  protected route = '';

  protected readonly environment = environment;

  protected cookieConsent = {
    functionality: false,
    performance: false,
    marketing: false,
  };

  protected privacySettings = {
    saleInformation: true,
    shareInformation: true,
    processInformationForAdvertising: true,
  };

  constructor(
    private consentService: ConsentService,
    private cookieService: CookieService,
    private googleAnalyticsService: GoogleAnalyticsService,
    protected localeService: LocaleService,
    private location: Location,
    private offcanvasService: NgbOffcanvas,
    private router: Router,
    protected translateService: TranslateService,
  ) {
    super();

    this.currentYear = (new Date).getFullYear();

    this.checkCookieConsent();
    this.checkPrivacySettings();

    this.track(
      this.router.events.subscribe((event) => {
        if (event instanceof ActivationEnd) {
          const locationState = this.location.getState() as any;
          if (locationState?.chooseLocale) {
            this.showChooseLocale = true;
          }

          this.route = this.router.url.split('/')[2] || '';
        }
      })
    );

    this.track(
      this.localeService.userCountryDetected.subscribe((_) => {
        this.checkCookieConsent();
        this.checkPrivacySettings();
      })
    );
  }

  public hideChooseLocale() {
    this.location.replaceState(this.location.path(), '', {chooseLocale: false});
    this.showChooseLocale = false;
  }

  public showCookieDetails() {
    this.showAcceptCookiesDetails = true;
  }

  public showPrivacyDetails() {
    this.showPrivacySettingsDetails = true;
  }

  public acceptCookies() {
    this.cookieConsent = {
      functionality: true,
      performance: true,
      marketing: true,
    };
    this.saveCookieDetails();
  }

  public rejectCookies() {
    this.cookieConsent = {
      functionality: false,
      performance: false,
      marketing: false,
    };
    this.saveCookieDetails();
  }

  public saveCookieDetails() {
    // set cookie consent for 6 months max
    // TODO set custom expiration time per region (for example, for US and Europe)
    // GDPR (Europe) 6 months
    this.cookieService.set('cookie_consent', JSON.stringify(this.cookieConsent), this.addMonths(new Date, 6));

    let uuid: string;
    if (this.cookieService.check('uuid')) {
      uuid = this.cookieService.get('uuid')
    } else {
      uuid = uuidv4();
    }

    this.cookieService.set('uuid', uuid);
    this.consentService.saveConsent(uuid, this.cookieConsent).subscribe({
      next: () => {
        this.setGAConsent();
      },
    });

    this.showAcceptCookies = false;
  }

  public acceptPrivacySettings() {
    this.privacySettings = {
      saleInformation: true,
      shareInformation: true,
      processInformationForAdvertising: true,
    };
    this.savePrivacyDetails();
  }

  public savePrivacyDetails() {
    // TODO set custom expiration time per region (for example, for US, Canada and Mexico)
    this.cookieService.set('privacy_settings', JSON.stringify(this.privacySettings), this.addMonths(new Date, 6));
    this.setGAPrivacySettings();
    this.showPrivacySettings = false;
  }

  public openOffCanvas(content: any) {
    this.offcanvasService.open(content, {ariaLabelledBy: 'offcanvas-basic-title', panelClass: 'offcanvas-navbar'});
  }

  private checkCookieConsent() {
    this.showAcceptCookies = this.localeService.cookieBannerNeeded() && !this.cookieService.check('cookie_consent');

    if (this.cookieService.check('cookie_consent')) {
      this.cookieConsent = JSON.parse(this.cookieService.get('cookie_consent'));
    } else if (localStorage.getItem(COUNTRY_CACHE_NAME) && !this.localeService.cookieConsentNeeded()) {
      this.cookieConsent = {
        functionality: true,
        performance: true,
        marketing: true,
      };
    }

    this.setGAConsent();
  }

  private checkPrivacySettings() {
    this.showPrivacySettings = this.localeService.privacyBannerNeeded() && !this.cookieService.check('privacy_settings');

    if (this.cookieService.check('privacy_settings')) {
      this.privacySettings = JSON.parse(this.cookieService.get('privacy_settings'));
    } else if (localStorage.getItem(COUNTRY_CACHE_NAME)) {
      this.privacySettings = {
        saleInformation: true,
        shareInformation: true,
        processInformationForAdvertising: true,
      };
    }

    this.setGAPrivacySettings();
  }

  private setGAConsent() {
    this.googleAnalyticsService.gtag('consent', 'update', {
      'ad_storage': this.cookieConsent.marketing ? 'granted' : 'denied',
      'ad_user_data': this.cookieConsent.marketing ? 'granted' : 'denied',
      'ad_personalization': this.cookieConsent.marketing ? 'granted' : 'denied',
      'analytics_storage': this.cookieConsent.performance ? 'granted' : 'denied',
      'functionality_storage': this.cookieConsent.functionality ? 'granted' : 'denied',
      'personalization_storage': this.cookieConsent.functionality ? 'granted' : 'denied',
      'security_storage': this.cookieConsent.functionality ? 'granted' : 'denied',
    });
  }

  private setGAPrivacySettings() {
    this.googleAnalyticsService.gtag('consent', 'update', {
      'ad_storage': this.privacySettings.processInformationForAdvertising ? 'granted' : 'denied',
      'ad_user_data': this.privacySettings.processInformationForAdvertising ? 'granted' : 'denied',
      'ad_personalization': this.privacySettings.processInformationForAdvertising ? 'granted' : 'denied',
      'analytics_storage': this.privacySettings.shareInformation ? 'granted' : 'denied',
      'functionality_storage': this.privacySettings.shareInformation ? 'granted' : 'denied',
      'personalization_storage': this.privacySettings.shareInformation ? 'granted' : 'denied',
      'security_storage': this.privacySettings.shareInformation ? 'granted' : 'denied',
    });
  }

  private addMonths(date: Date, months: number) {
    date.setMonth(date.getMonth() + months);

    return date;
  }
}
