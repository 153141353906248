import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {Observable} from 'rxjs';
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class AcceptLanguageInterceptor implements HttpInterceptor {

  constructor(
    private translateService: TranslateService,
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes('assets/i18n') || request.url.includes('ip2c.org')) { // don't intercept requests from @ngx-translate and to ip2c.org
      return next.handle(request);
    }

    const locale = this.translateService.currentLang;

    if (locale) {
      request = request.clone({
        setHeaders: {
          'Accept-Language': locale,
        }
      });
    }

    return next.handle(request);
  }
}
