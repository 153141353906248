import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from "@angular/common";

@Pipe({
  name: 'customCurrency',
  standalone: true
})
export class CustomCurrencyPipe implements PipeTransform {
  constructor(
    private currencyPipe: CurrencyPipe,
  ) {
  }

  transform(value: number | string | null | undefined, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): string | null {
    if (digitsInfo === '1.0-2') {
      if (value as number % 1 > 0) {
        return this.currencyPipe.transform(value, currencyCode, display, '1.2-2', locale);
      } else {
        return this.currencyPipe.transform(value, currencyCode, display, '1.0-0', locale);
      }
    } else {
      return this.currencyPipe.transform(value, currencyCode, display, digitsInfo, locale);
    }
  }

}
