import {TranslateLoader} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";

import {Observable} from "rxjs";

export class CustomTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient, public prefix: string = "/assets/i18n/", public suffix: string = ".json") {
  }

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<Object> {
    // TODO check if "lang" file exists, and load it, also DO THIS IN API repo
    return this.http.get(`${this.prefix}${lang.split('-')[0]}${this.suffix}`);
  }
}
