<div class="about">
  <section class="row py-5 about-header">
    <div class="text-center py-5 px-3">
      <div class="container">
        <h2 class="fw-bold mb-4">
        <span
          class="text-primary">{{'components.about-us.freeing-your-business-title1' | translate}}</span> {{'components.about-us.freeing-your-business-title2' | translate}}
        </h2>
        <p>{{'components.about-us.freeing-your-business-description' | translate}}</p>
      </div>
    </div>
  </section>

  <section class="py-5">
    <div class="text-center px-3">
      <h2 class="fw-bold mb-4">{{'components.about-us.numbers-title' | translate}}</h2>
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-6 col-lg mb-5">
            <div class="text-center">
              <div class="mb-2 display-6 fw-medium text-primary">80%</div>
              <p class="mb-0">{{'components.about-us.numbers-item1' | translate}}</p>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg mb-5">
            <div class="text-center">
              <div class="mb-2 display-6 fw-medium text-primary">37%</div>
              <p class="mb-0">{{'components.about-us.numbers-item2' | translate}}</p>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg mb-5">
            <div class="text-center">
              <div class="mb-2 display-6 fw-medium text-primary">35%</div>
              <p class="mb-0">{{'components.about-us.numbers-item3' | translate}}</p>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg mb-5">
            <div class="text-center">
              <div class="mb-2 display-6 fw-medium text-primary">50%</div>
              <p class="mb-0">{{'components.about-us.numbers-item4' | translate}}</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>

  <section class="row bg-light py-5">
    <div class="text-center px-3">
      <h2 class="fw-bold mb-4">
        <span
          class="text-primary">{{'components.about-us.integrations1' | translate}}</span> {{'components.about-us.integrations2' | translate}}
      </h2>
      <div class="row justify-content-center">
        <div class="col-auto px-5 py-3">
          <img alt="" class="grayscale" src="/assets/logos/google.svg"/>
        </div>
        <div class="col-auto px-5 py-3">
          <img alt="" class="grayscale opacity-75" src="/assets/logos/meta.svg"/>
        </div>
      </div>
    </div>
  </section>

  <section class="py-5">
    <div class="text-center px-3">
      <h2 class="fw-bold mb-4">{{'components.about-us.years-title' | translate}}</h2>
      <p>{{'components.about-us.years-description1' | translate}} {{currentYear - 2011}} {{'components.about-us.years-description2' | translate}}</p>
    </div>
  </section>

  <section class="py-5">
    <div class="px-3">
      <h2 class="text-center fw-bold mb-4">{{'components.about-us.securing-your-data-title' | translate}}</h2>
      <div class="row">
        <div class="col-12 col-sm-6 col-lg-4">
          <div class="btn btn-warning p-3 border-radius-50 cursor-default">
            <bi class="icon-medium" name="diagram-3"></bi>
          </div>
          <h5 class="mt-2">{{'components.about-us.securing-your-data-item1-title' | translate}}</h5>
          <p>{{'components.about-us.securing-your-data-item1-description1' | translate}} <a
            href="https://aws.amazon.com/"
            target="_blank">{{'components.about-us.securing-your-data-aws' | translate}}</a> {{'components.about-us.securing-your-data-item1-description2' | translate}}
          </p>
        </div>
        <!-- TODO add GDPR to privacy policy before launching in Europe -->
        <div *ngIf="false" class="col-12 col-sm-6 col-lg-4 mb-7">
          <div class="btn btn-warning p-3 border-radius-50 cursor-default">
            <bi class="icon-medium" name="lock"></bi>
          </div>
          <h5 class="mt-2">{{'components.about-us.securing-your-data-item2-title' | translate}}</h5>
          <p>{{'components.about-us.securing-your-data-item2-description1' | translate}} <a
            routerLink="/{{translateService.currentLang}}/info/privacy-policy"
            target="_blank">{{'menu-items.privacy-policy' | translate}}</a>{{'components.about-us.securing-your-data-item2-description2' | translate}}
          </p>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 mb-7">
          <div class="btn btn-warning p-3 border-radius-50 cursor-default">
            <bi class="icon-medium" name="shield-lock"></bi>
          </div>
          <h5 class="mt-2">{{'components.about-us.securing-your-data-item3-title' | translate}}</h5>
          <p>{{'components.about-us.securing-your-data-item3-description' | translate}}</p>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 mb-7">
          <div class="btn btn-warning p-3 border-radius-50 cursor-default">
            <bi class="icon-medium" name="credit-card"></bi>
          </div>
          <h5 class="mt-2">{{'components.about-us.securing-your-data-item4-title' | translate}}</h5>
          <p>{{'components.about-us.securing-your-data-item4-description' | translate}}</p>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 mb-7">
          <div class="btn btn-warning p-3 border-radius-50 cursor-default">
            <bi class="icon-medium" name="hdd"></bi>
          </div>
          <h5 class="mt-2">{{'components.about-us.securing-your-data-item5-title' | translate}}</h5>
          <p>{{'components.about-us.securing-your-data-item5-description' | translate}}</p>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 mb-7">
          <div class="btn btn-warning p-3 border-radius-50 cursor-default">
            <bi class="icon-medium" name="shield-check"></bi>
          </div>
          <h5 class="mt-2">{{'components.about-us.securing-your-data-item6-title' | translate}}</h5>
          <p>{{'components.about-us.securing-your-data-item6-description1' | translate}} <a
            href="https://aws.amazon.com/"
            target="_blank">{{'components.about-us.securing-your-data-amazon-web-services' | translate}}</a>{{'components.about-us.securing-your-data-item6-description2' | translate}}
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="py-5">
    <div class="text-center px-3">
      <h2 class="fw-bold mb-4">{{'components.about-us.the-team-title' | translate}}</h2>
      <p>{{'components.about-us.the-team-description' | translate}}</p>
    </div>
  </section>

  <section class="py-5">
    <div class="text-center px-3">
      <h2 class="fw-bold mb-4">{{'components.about-us.useful-links-title' | translate}}</h2>
      <div class="justify-content-center row">
        <div class="col-12 col-sm-6 col-lg-4 p-0">
          <div class="bg-light p-4 m-2">
            <a class="nav-link d-flex" gaEvent="instagram-about-us_click"
               href="https://www.instagram.com/agendacita.mx/" target="_blank">
              <div class="btn p-0">
                <bi class="icon-medium" name="instagram"></bi>
              </div>
              <div class="d-flex flex-column text-start ps-3">
                <h6 class="fw-bold mb-1">Instagram</h6>
                <p class="mb-0">{{'components.about-us.instagram-description' | translate}}</p>
              </div>
            </a>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 p-0">
          <div class="bg-light p-4 m-2">
            <a class="nav-link d-flex" routerLink="/{{translateService.currentLang}}/info/faq"
               target="_blank">
              <div class="btn p-0">
                <bi class="icon-medium" name="book"></bi>
              </div>
              <div class="d-flex flex-column text-start ps-3">
                <h6 class="fw-bold mb-1">{{'components.about-us.knowledge-base-title' | translate}}</h6>
                <p class="mb-0">{{'components.about-us.knowledge-base-description' | translate}}</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
