import {Component} from '@angular/core';

import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent {
  protected currentYear = 2023;

  constructor(
    protected translateService: TranslateService,
  ) {
    this.currentYear = (new Date).getFullYear();
  }
}
