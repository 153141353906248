<div class="notifications pt-5 pt-md-0">
  <section class="first-section container d-md-flex align-items-center text-center text-md-start">
    <div class="row align-items-center">
      <div class="col-md-7">
        <h1 class="fw-bold">{{'components.notifications.appointment-reminders-title' | translate}}</h1>
        <p
          class="mt-3 mb-4 col-md-9 pe-3">{{'components.notifications.appointment-reminders-description' | translate}}</p>
        <a class="btn btn-warning px-4 py-2 text-uppercase fw-semibold"
           gaCategory="sign-up" gaEvent="sign-up-notifications_click"
           href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.start-free-trial' | translate}}</a>
        <div class="mt-4 text-left">
          <a class="link-underline link-underline-opacity-25"
             gaCategory="sign-up" gaEvent="sign-up-notifications_click"
             href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.7-days-free-trial' | translate}}</a><br/>
          <span class="text-secondary">{{'components.common.no-credit-card-required' | translate}}
            <br/>{{'components.common.free-system-setup' | translate}}</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-9 col-lg-8 position-illust">
      <figure>
        <img alt="Image Description" class="w-70 position-illust"
             src="/assets/img/notification-and-reminders-{{translateService.currentLang.split('-')[0]}}.png">
      </figure>
    </div>
  </section>

  <section class="py-5">
    <div class="container overflow-visible z-3">
      <div class="row justify-content-lg-center">
        <div class="col-sm-12 col-lg-6 col-xl-4">
          <div class="media transition-hover rounded bg-primary text-white">
            <div class="media-body">
              <div class="p-5">
                <div class="mb-4">
                  <div class="btn btn-warning rounded-circle text-white p-2-5 cursor-default">
                    <bi class="icon-large" name="clock-history"></bi>
                  </div>
                </div>
                <h3 class="fw-bold h5">{{'components.notifications.reduce-late-arrivals-title1' | translate}}
                  60%</h3>
                <p class="mb-4">{{'components.notifications.reduce-late-arrivals-title2' | translate}}</p>
                <p>{{'components.notifications.reduce-late-arrivals-description' | translate}}</p>
                <a class="btn btn-light rounded-pill px-4 py-2 text-uppercase fw-semibold"
                   gaCategory="sign-up" gaEvent="sign-up-notifications_click"
                   href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.start-free-trial' | translate}}</a>
              </div>
              <div class="oval-divider--bottom oval-divider--9 position-static"></div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-6 col-xl-4 mt-5">
          <div class="media transition-hover rounded bg-light">
            <div class="media-body">
              <div class="p-5">
                <div class="mb-4">
                  <div class="btn btn-warning rounded-circle text-white p-2-5 cursor-default">
                    <bi class="icon-large" name="app-indicator"></bi>
                  </div>
                </div>
                <h3 class="fw-bold h5">{{'components.notifications.customize-reminders-title1' | translate}}</h3>
                <p class="fw-light">{{'components.notifications.customize-reminders-title2' | translate}}</p>
                <p>{{'components.notifications.customize-reminders-description' | translate}}</p>
                <a class="btn btn-warning w-100 mt-2 px-4 py-2 text-uppercase fw-semibold"
                   gaCategory="sign-up" gaEvent="sign-up-notifications_click"
                   href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.start-free-trial' | translate}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="row align-items-center parallax-block parallax-notification my-4" data-overlay="5"
           style="background-image: url('/assets/img/parallax-img/notification-{{translateService.currentLang.split('-')[0]}}.jpg')">
    <div class="z-0">
      <div class="container">
        <div class="col-md-8">
          <div class="mb-5">
            <h2
              class="fw-bold text-white">{{'components.notifications.communication-with-clients-title' | translate}}</h2>
            <p
              class="text-white">{{'components.notifications.communication-with-clients-description' | translate}}</p>
          </div>
          <a class="btn btn-outline-light mt-2 px-4 py-2 text-uppercase fw-semibold"
             gaCategory="sign-up" gaEvent="sign-up-notifications_click"
             href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.start-free-trial' | translate}}</a>
        </div>
      </div>
    </div>
  </section>

  <section class="pt-5 pb-3">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center title-img">
          <h3 class="fw-bold h5 mt-0 mb-3 pe-lg-5">
            <div class="btn btn-warning rounded-circle text-white p-2-5 cursor-default me-2">
              <bi class="icon-large" name="people"></bi>
            </div>
            {{'components.notifications.team-notifications-title' | translate}}
          </h3>
        </div>
      </div>
      <div class="row px-3 mt-6 justify-content-lg-center">
        <div class="col-md-6 col-lg-5 offset-xl-1">
          <ul class="p-0 check-list mb-0">
            <li>{{'components.notifications.team-notifications-item1' | translate}}</li>
            <li>{{'components.notifications.team-notifications-item2' | translate}}</li>
          </ul>
        </div>
        <div class="col-md-6 col-lg-5 offset-xl-1">
          <ul class="p-0 check-list mb-0">
            <li>{{'components.notifications.team-notifications-item3' | translate}}</li>
            <li>{{'components.notifications.team-notifications-item4' | translate}}</li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <section class="pt-3 pb-5">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center title-img">
          <h3 class="fw-bold h5 mt-0 mb-3 pe-lg-5">
            <div class="btn btn-warning rounded-circle text-white p-2-5 cursor-default me-2">
              <bi class="icon-large" name="app-indicator"></bi>
            </div>
            {{'components.notifications.client-notifications-title' | translate}}
          </h3>
        </div>
      </div>
      <div class="row px-3 mt-6 justify-content-lg-center">
        <div class="col-md-6 col-lg-5 offset-xl-1">
          <ul class="p-0 check-list mb-0">
            <li>{{'components.notifications.client-notifications-item1' | translate}}</li>
            <li>{{'components.notifications.client-notifications-item2' | translate}}</li>
            <li>{{'components.notifications.client-notifications-item3' | translate}}</li>
            <li>{{'components.notifications.client-notifications-item4' | translate}}</li>
          </ul>
        </div>
        <div class="col-md-6 col-lg-5 offset-xl-1">
          <ul class="p-0 check-list mb-0">
            <li>{{'components.notifications.client-notifications-item5' | translate}}</li>
            <li>{{'components.notifications.client-notifications-item6' | translate}}</li>
            <li>{{'components.notifications.client-notifications-item7' | translate}}</li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <section class="text-center py-5">
    <div class="container">
      <div class="row">
        <div class="col-md-12 mb-4">
          <h2 class="fw-bold">{{'components.notifications.emails-title1' | translate}}</h2>
          <p class="fw-light">{{'components.notifications.emails-title2' | translate}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5 col-lg-6">
          <div class="px-3 mb-5 mb-md-0">
            <div class="btn btn-warning rounded-circle text-white p-3 cursor-default">
              <bi class="icon-large" name="pencil"></bi>
            </div>
            <h3 class="fw-bold h5 mb-3 mt-4">{{'components.notifications.emails-item1-title' | translate}}</h3>
            <p>{{'components.notifications.emails-item1-description' | translate}}</p>
          </div>
        </div>
        <div class="col-md-7 col-lg-6">
          <div class="px-3">
            <div class="btn btn-warning rounded-circle text-white p-3 cursor-default">
              <bi class="icon-large" name="image"></bi>
            </div>
            <h3 class="fw-bold h5 mb-3 mt-4">{{'components.notifications.emails-item2-title' | translate}}</h3>
            <p>{{'components.notifications.emails-item2-description' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center pt-5">
          <a class="btn btn-warning px-4 py-2 text-uppercase fw-semibold"
             gaCategory="sign-up" gaEvent="sign-up-notifications_click"
             href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.start-free-trial' | translate}}</a>
        </div>
      </div>
    </div>
  </section>

  <section class="py-5">
    <div class="container pt-5">
      <div class="row">
        <div class="col-12 text-center">
          <h3 class="fw-bold mb-4">{{'components.common.join-businesses1' | translate}}
            <br>{{'components.common.join-businesses2' | translate}}</h3>
          <p class="text-center mt-4">
            <a class="btn btn-warning px-4 py-2 text-uppercase fw-semibold"
               gaCategory="sign-up" gaEvent="sign-up-notifications_click"
               href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.try-all-the-features' | translate}}
              <br class="d-md-none"> {{'components.common.7-days-free-trial2' | translate}}</a>
          </p>
        </div>
      </div>
    </div>
  </section>
</div>
