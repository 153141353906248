import {Component} from '@angular/core';

import {TranslateService} from "@ngx-translate/core";

import {environment} from "../../environments/environment";

@Component({
  selector: 'app-online-booking',
  templateUrl: './online-booking.component.html',
  styleUrls: ['./online-booking.component.scss']
})
export class OnlineBookingComponent {
  protected readonly environment = environment;

  constructor(
    protected translateService: TranslateService,
  ) {
  }

}
