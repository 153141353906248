import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';

import {BootstrapIconsModule} from 'ng-bootstrap-icons';
import {
  Alarm,
  AppIndicator,
  Archive,
  Bag,
  BarChartLine,
  Bell,
  Book,
  Box,
  Calendar4Week,
  CalendarCheck,
  CalendarEvent,
  CalendarWeek,
  CardChecklist,
  Chat,
  Check,
  ClockHistory,
  Cloud,
  CloudDownload,
  CreditCard,
  Diagram3,
  Display,
  EmojiSmile,
  Envelope,
  FilePlus,
  FilePlusFill,
  Gear,
  GraphUpArrow,
  Hdd,
  Image,
  InfoCircle,
  InfoCircleFill,
  Instagram,
  List,
  ListCheck,
  Lock,
  Pencil,
  People,
  PeopleFill,
  Person,
  PersonRolodex,
  Phone,
  PlusLg,
  Scissors,
  Search,
  ShieldCheck,
  ShieldLock,
  Sliders,
  Tag,
  ThreeDots,
  TicketPerforated,
  ViewList,
} from 'ng-bootstrap-icons/icons';

const icons = {
  Alarm,
  AppIndicator,
  Archive,
  Bag,
  BarChartLine,
  Bell,
  Book,
  Box,
  Calendar4Week,
  CalendarCheck,
  CalendarWeek,
  CalendarEvent,
  CardChecklist,
  Chat,
  Check,
  ClockHistory,
  Cloud,
  CloudDownload,
  CreditCard,
  Diagram3,
  Display,
  EmojiSmile,
  Envelope,
  FilePlus,
  FilePlusFill,
  Gear,
  GraphUpArrow,
  Hdd,
  Image,
  InfoCircle,
  InfoCircleFill,
  Instagram,
  List,
  ListCheck,
  Lock,
  Pencil,
  People,
  PeopleFill,
  Person,
  PersonRolodex,
  Phone,
  PlusLg,
  Scissors,
  Search,
  ShieldLock,
  ShieldCheck,
  Sliders,
  Tag,
  ThreeDots,
  TicketPerforated,
  ViewList,
};

@NgModule({
  declarations: [],
  imports: [
    MatIconModule,
    BootstrapIconsModule.pick(icons)
  ],
  exports: [
    MatIconModule,
    BootstrapIconsModule,
  ]
})
export class IconsModule {
}
