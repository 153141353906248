import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-choose-country-region',
  templateUrl: './choose-country-region.component.html',
  styleUrls: ['./choose-country-region.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChooseCountryRegionComponent {

  constructor() {
  }

}
