<div class="pricing container py-5">
  <h2 class="fw-bold text-center mb-5">{{'components.pricing.title' | translate}}</h2>

  <div *ngIf="loading" class="loader text-center">
    <div class="spinner-border" role="status"></div>
  </div>

  <div *ngIf="businessTypes[translateService.currentLang]"
       class="d-flex flex-row flex-wrap justify-content-between align-items-stretch w-100">
    <div *ngFor="let businessType of Object.keys(businessTypes[translateService.currentLang]); let i = index"
         class="pricing-group-wrapper flex-grow-1">
      <div class="pricing-group" routerLink="/{{translateService.currentLang}}/pricing/{{i + 1}}">
        <div class="group-image-wrapper">
          <div class="btn btn-warning p-2 border-radius-50 cursor-default">
            @switch (i + 1) {
              @case (1) {
                <bi class="icon-small" name="scissors"></bi>
              }
              @case (2) {
                <mat-icon class="icon-small">school</mat-icon>
              }
              @case (3) {
                <bi class="icon-small" name="ticket-perforated"></bi>
              }
              @case (4) {
                <mat-icon class="icon-small">fitness_center</mat-icon>
              }
              @case (5) {
                <mat-icon class="icon-small">construction</mat-icon>
              }
              @case (6) {
                <bi class="icon-small" name="bag"></bi>
              }
              @case (7) {
                <mat-icon class="icon-small material-symbols-outlined">medical_services</mat-icon>
              }
              @case (8) {
                <mat-icon class="icon-small">directions_car</mat-icon>
              }
              @case (9) {
                <mat-icon class="icon-small">gavel</mat-icon>
              }
            }
          </div>
        </div>
        <div class="fw-semibold">{{businessType}}</div>
        <div
          class="my-2 text-secondary overflow-hidden">{{businessTypes[translateService.currentLang][businessType].join(', ')}}
        </div>
        <div (click)="toggleGroup($event)"
             class="pricing-group-show-btn">{{'components.pricing.button-more' | translate}}
        </div>
        <div (click)="toggleGroup($event)"
             class="pricing-group-hide-btn">{{'components.pricing.button-hide' | translate}}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!loading" class="pricing-groups-footer">
    <div>
      <div class="fw-semibold fs-5 mb-2">{{'components.pricing.footer-title' | translate}}</div>
      <div class="text-secondary">{{'components.pricing.footer-description' | translate}}</div>
    </div>
    <a href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">
      <div class="btn btn-warning px-4 py-2 fw-medium text-nowrap align-self-center"
           gaCategory="sign-up" gaEvent="sign-up-pricing_click">{{'components.common.try-it-for-free' | translate}}
      </div>
    </a>
  </div>
</div>
