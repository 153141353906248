import {NgModule} from '@angular/core';
import {Location} from '@angular/common';
import {RouterModule, TitleStrategy} from '@angular/router';

import {
  CacheMechanism,
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
  ManualParserLoader
} from "@gilsdav/ngx-translate-router";
import {TranslateService} from "@ngx-translate/core";

import {routes} from "./routes";
import {locales} from "./locales";
import {LocaleService} from "./services/locale.service";
import {CustomPageTitleStrategy} from "./lib/custom-page-title-strategy";

export function createLocalizeParser(
  translateService: TranslateService,
  location: Location,
  settings: LocalizeRouterSettings,
  localeService: LocaleService,
) {
  const cachedLanguage = localStorage.getItem(LOCALIZE_CACHE_NAME);
  if (cachedLanguage === null) {
    location.replaceState('', '', {chooseLocale: true});
    localeService.getUserCountry().then((country) => {
      const locale = localeService.getDefaultLocale(country);
      localeService.changeLocale(locale);
      localeService.userCountryDetected.emit();
    }).catch((_) => {
    });
  }

  return new ManualParserLoader(translateService, location, settings, locales);
}

export const LOCALIZE_CACHE_NAME = 'LOCALIZE_DEFAULT_LANGUAGE';

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: createLocalizeParser,
        deps: [TranslateService, Location, LocalizeRouterSettings, LocaleService]
      },
      alwaysSetPrefix: true,
      useCachedLang: true,
      cacheMechanism: CacheMechanism.LocalStorage,
      cacheName: LOCALIZE_CACHE_NAME,
    }), // after RouterModule
  ],
  providers: [
    {provide: TitleStrategy, useClass: CustomPageTitleStrategy},
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
