<div class="client-database pt-5 pt-md-0">
  <section class="first-section container d-md-flex align-items-center text-center text-md-start">
    <div class="row align-items-center">
      <div class="col-md-8">
        <h1 class="fw-bold">{{'components.client-database.client-database-title' | translate}}</h1>
        <p class="mt-3 mb-4 col-md-9 pe-3">{{'components.client-database.client-database-description1' | translate}}
          <br/>{{'components.client-database.client-database-description2' | translate}}</p>
        <a class="btn btn-warning px-4 py-2 text-uppercase fw-semibold"
           gaCategory="sign-up" gaEvent="sign-up-client-base_click"
           href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.try-for-free' | translate}}</a>
        <div class="mt-4 text-left">
          <a class="link-underline link-underline-opacity-25"
             gaCategory="sign-up" gaEvent="sign-up-client-base_click"
             href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.7-days-free-trial' | translate}}</a><br/>
          <span class="text-secondary">{{'components.common.no-credit-card-required' | translate}}
            <br/>{{'components.common.cancel-anytime' | translate}}</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-7 col-lg-7 position-illust">
      <figure>
        <img alt="Image Description" class="w-70 position-illust" src="/assets/img/client-database.png">
      </figure>
    </div>
  </section>

  <section class="py-5">
    <div class="container overflow-visible z-3">
      <div class="row justify-content-lg-center">
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-5">
          <div class="rounded bg-primary text-white">
            <div class="p-5">
              <div class="mb-4">
                <div class="btn btn-warning rounded-circle text-white p-2-5 cursor-default">
                  <bi class="icon-large" name="book"></bi>
                </div>
              </div>
              <h3
                class="fw-bold h5">{{'components.client-database.collect-data-title1' | translate}}</h3>
              <p class="mb-4">{{'components.client-database.collect-data-title2' | translate}}</p>
              <p>{{'components.client-database.collect-data-description' | translate}}</p>
              <a class="btn btn-light rounded-pill px-4 py-2 text-uppercase fw-semibold"
                 gaCategory="sign-up" gaEvent="sign-up-client-base_click"
                 href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.try-for-free' | translate}}</a>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-0 mt-5">
          <div class="rounded bg-light">
            <div class="p-5">
              <div class="mb-4">
                <div class="btn btn-warning rounded-circle text-white p-2-5 cursor-default">
                  <bi class="icon-large" name="display"></bi>
                </div>
              </div>
              <h3
                class="fw-bold h5">{{'components.client-database.data-in-one-place-title1' | translate}}</h3>
              <p class="mb-4">{{'components.client-database.data-in-one-place-title2' | translate}}</p>
              <p>{{'components.client-database.data-in-one-place-description1' | translate}}<br>
                {{'components.client-database.data-in-one-place-description2' | translate}}</p>
              <a class="btn btn-warning px-4 py-2 text-uppercase fw-semibold"
                 gaCategory="sign-up" gaEvent="sign-up-client-base_click"
                 href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.try-for-free' | translate}}</a>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-4 mb-0 mt-5">
          <div class="rounded bg-light">
            <div class="p-5">
              <div class="mb-4">
                <div class="btn btn-warning rounded-circle text-white p-2-5 cursor-default">
                  <bi class="icon-large" name="diagram-3"></bi>
                </div>
              </div>
              <h3
                class="fw-bold h5">{{'components.client-database.unified-client-database-title1' | translate}}</h3>
              <p class="mb-4">{{'components.client-database.unified-client-database-title2' | translate}}</p>
              <p>{{'components.client-database.unified-client-database-description' | translate}}</p>
              <a class="btn btn-warning px-4 py-2 text-uppercase fw-semibold"
                 gaCategory="sign-up" gaEvent="sign-up-client-base_click"
                 href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.try-for-free' | translate}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="row align-items-center parallax-block parallax-database1 my-4" data-overlay="5">
    <div class="z-0">
      <div class="container">
        <div class="col-md-8">
          <div class="mb-5">
            <h2
              class="fw-bold text-white">{{'components.client-database.segments-title' | translate}}</h2>
            <p class="text-white">{{'components.client-database.segments-description' | translate}}</p>
          </div>
          <a class="btn btn-outline-light mt-2 px-4 py-2 text-uppercase fw-semibold"
             gaCategory="sign-up" gaEvent="sign-up-client-base_click"
             href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.try-for-free' | translate}}</a>
        </div>
      </div>
    </div>
  </section>

  <section class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <h2 class="fw-bold mb-3">{{'components.client-database.customer-filtering-title' | translate}}</h2>
          <p>{{'components.client-database.customer-filtering-description1' | translate}}
            <br>{{'components.client-database.customer-filtering-description2' | translate}}</p>
        </div>
      </div>
      <div class="row px-3 mt-6 justify-content-lg-center">
        <div class="col-md-6 col-lg-5 offset-xl-1">
          <ul class="p-0 check-list mb-0">
            <li>{{'components.client-database.customer-filtering-item1' | translate}}</li>
            <li>{{'components.client-database.customer-filtering-item2' | translate}}</li>
            <li>{{'components.client-database.customer-filtering-item3' | translate}}</li>
            <li>{{'components.client-database.customer-filtering-item4' | translate}}</li>
            <li>{{'components.client-database.customer-filtering-item5' | translate}}</li>
          </ul>
        </div>
        <div class="col-md-6 col-lg-5 offset-xl-1">
          <ul class="p-0 check-list mb-0">
            <li>{{'components.client-database.customer-filtering-item6' | translate}}</li>
            <li>{{'components.client-database.customer-filtering-item7' | translate}}</li>
            <li>{{'components.client-database.customer-filtering-item8' | translate}}</li>
            <li>{{'components.client-database.customer-filtering-item9' | translate}}</li>
            <li>{{'components.client-database.customer-filtering-item10' | translate}}</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center pt-5">
          <a class="btn btn-warning px-4 py-2 text-uppercase fw-semibold"
             gaCategory="sign-up" gaEvent="sign-up-client-base_click"
             href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.try-for-free' | translate}}</a>
        </div>
      </div>
    </div>
  </section>

  <section class="py-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6 col-lg-6">
          <div class="text-center">
            <div class="btn btn-warning rounded-circle text-white p-2-5 cursor-default me-2">
              <bi class="icon-large" name="people"></bi>
            </div>
            <h3
              class="fw-medium h5 mb-0 py-2">{{'components.client-database.automatic-segmentation-title' | translate}}</h3>
            <p
              class="px-lg-6 mb-0">{{'components.client-database.automatic-segmentation-description' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center pt-5">
          <a class="btn btn-warning px-4 py-2 text-uppercase fw-semibold"
             gaCategory="sign-up" gaEvent="sign-up-client-base_click"
             href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.try-for-free' | translate}}</a>
        </div>
      </div>
    </div>
  </section>

  <section class="row align-items-center parallax-block parallax-database2 my-4" data-overlay="5">
    <div class="z-0">
      <div class="container">
        <div class="col-md-8">
          <div class="mb-5">
            <h2
              class="fw-bold text-white">{{'components.client-database.group-your-clients-title' | translate}}</h2>
            <p class="text-white">{{'components.client-database.group-your-clients-description' | translate}}</p>
          </div>
          <a class="btn btn-outline-light mt-2 px-4 py-2 text-uppercase fw-semibold"
             gaCategory="sign-up" gaEvent="sign-up-client-base_click"
             href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.try-for-free' | translate}}</a>
        </div>
      </div>
    </div>
  </section>

  <section class="pt-3 pb-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6 col-lg-6">
          <div class="text-center">
            <h3
              class="fw-medium h5 mb-0 py-2">{{'components.client-database.customer-group-mailing-title' | translate}}</h3>
            <p
              class="px-lg-6 mb-0">{{'components.client-database.customer-group-mailing-description' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="py-5">
    <div class="container pt-5">
      <div class="row">
        <div class="col-12 text-center">
          <h3 class="fw-bold mb-4">{{'components.common.join-businesses1' | translate}}
            <br>{{'components.common.join-businesses2' | translate}}</h3>
          <p class="text-center mt-4">
            <a class="btn btn-warning px-4 py-2 text-uppercase fw-semibold"
               gaCategory="sign-up" gaEvent="sign-up-client-base_click"
               href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.try-all-the-features' | translate}}
              <br class="d-md-none"> {{'components.common.7-days-free-trial2' | translate}}</a>
          </p>
        </div>
      </div>
    </div>
  </section>
</div>
