import {Component} from '@angular/core';

import {TranslateService} from "@ngx-translate/core";

import {environment} from "../../environments/environment";

@Component({
  selector: 'app-client-database',
  templateUrl: './client-database.component.html',
  styleUrls: ['./client-database.component.scss']
})
export class ClientDatabaseComponent {
  protected readonly environment = environment;

  constructor(
    protected translateService: TranslateService,
  ) {
  }
}
