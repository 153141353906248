import {Component, Renderer2} from '@angular/core';
import {HttpClient} from "@angular/common/http";

import {LangChangeEvent, TranslateService} from "@ngx-translate/core";

import {environment} from "../../environments/environment";
import {AbstractSubscriber} from "../services/abstract-subscriber";

type BusinessTypes = { [key: string]: string[] };

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent extends AbstractSubscriber {
  protected businessTypes: { [locale: string]: BusinessTypes } = {};

  protected loading = true;

  protected readonly environment = environment;
  protected readonly Object = Object;

  constructor(
    private httpClient: HttpClient,
    private renderer: Renderer2,
    protected translateService: TranslateService,
  ) {
    super();

    this.loadBusinessTypes();

    this.track(
      this.translateService.onLangChange.subscribe((_: LangChangeEvent) => {
        this.loadBusinessTypes();
      })
    );
  }

  protected toggleGroup(event: Event) {
    if (this.renderer.parentNode(event.target).classList.contains('pricing-group-active')) {
      this.renderer.removeClass(this.renderer.parentNode(event.target), 'pricing-group-active')
    } else {
      this.renderer.addClass(this.renderer.parentNode(event.target), 'pricing-group-active');
    }

    event.stopPropagation();
  }

  private loadBusinessTypes() {
    if (!this.businessTypes[this.translateService.currentLang]) {
      this.httpClient.get<BusinessTypes>(environment.apiUrl + '/public/businessTypes').subscribe({
        next: (businessTypes: BusinessTypes) => {
          this.businessTypes[this.translateService.currentLang] = businessTypes;

          this.loading = false;
        },
      });
    }
  }
}
