<section class="text-center py-5">
  <div class="choose-country-region py-5">
    <div class="row mb-5">
      <div class="col-12 text-center">
        <h2 class="fw-bold">{{'components.choose-country-region.title' | translate}}</h2>
      </div>
    </div>

    <div class="row justify-content-center fs-5">
      <app-choose-country-region-widget linkClass="link-primary"></app-choose-country-region-widget>
    </div>
  </div>
</section>
