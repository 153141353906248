import {Directive, OnDestroy} from '@angular/core';

import {Subscription} from 'rxjs';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractSubscriber implements OnDestroy {
  private trackers: Subscription[] = [];

  ngOnDestroy() {
    // Iterate over all observable subscriptions, unsubscribe and then clear the trackers array
    this.trackers.forEach((tracker: Subscription): void => {
      tracker.unsubscribe();
    });
    this.trackers = [];
  }

  /**
   * Accepts one to many observable subscriptions and tracks them via the trackers array
   * @param subs {Subscription} [One or more observable subscriptions]
   */
  protected track(...subs: Subscription[]): void {
    // eslint-disable-next-line prefer-spread
    this.trackers.push.apply(this.trackers, subs);
  }
}
