import {Pipe, PipeTransform} from '@angular/core';
import {getLocaleNumberSymbol, NumberSymbol} from "@angular/common";

import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'removeThousandsSeparator',
  standalone: true
})
export class RemoveThousandsSeparatorPipe implements PipeTransform {
  constructor(
    protected translateService: TranslateService,
  ) {
  }

  transform(value: string | null): string | null {
    if (value === null) {
      return null;
    }

    return value.replace(getLocaleNumberSymbol(this.translateService.currentLang, NumberSymbol.CurrencyGroup), '');
  }

}
