<ng-template #offcanvasNavbar let-offcanvas>
  <div class="offcanvas-header py-4">
    <span (click)="offcanvas.dismiss()" class="btn-close"></span>
  </div>
  <div class="offcanvas-body px-3">
    <a (click)="offcanvas.dismiss()" class="nav-link pb-4" routerLink="/{{translateService.currentLang}}">
      <h2>Agenda<span class="text-success">cita</span></h2>
    </a>
    <div class="row">
      <div class="col">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a (click)="offcanvas.dismiss()" class="nav-link"
               routerLink="/{{translateService.currentLang}}/online-booking"
               routerLinkActive="active">{{'menu-items.online-booking' | translate}}</a>
          </li>
          <li class="nav-item">
            <a (click)="offcanvas.dismiss()" class="nav-link"
               routerLink="/{{translateService.currentLang}}/appointment-schedule"
               routerLinkActive="active">{{'menu-items.appointment-scheduling' | translate}}</a>
          </li>
          <li class="nav-item">
            <a (click)="offcanvas.dismiss()" class="nav-link"
               routerLink="/{{translateService.currentLang}}/sms-and-email-notification"
               routerLinkActive="active">{{'menu-items.notifications-and-reminders' | translate}}</a>
          </li>
          <li class="nav-item">
            <a (click)="offcanvas.dismiss()" class="nav-link"
               routerLink="/{{translateService.currentLang}}/client-base"
               routerLinkActive="active">{{'menu-items.client-database' | translate}}</a>
          </li>
        </ul>
      </div>
      <div class="col">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a (click)="offcanvas.dismiss()" class="nav-link"
               routerLink="/{{translateService.currentLang}}/pricing"
               routerLinkActive="active">{{'menu-items.pricing' | translate}}</a>
          </li>
          <li class="nav-item">
            <a (click)="offcanvas.dismiss()" class="nav-link"
               routerLink="/{{translateService.currentLang}}/about"
               routerLinkActive="active">{{'menu-items.about-us' | translate}}</a>
          </li>
        </ul>
      </div>
      <div class="col-12 text-center pt-4">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a (click)="offcanvas.dismiss()" class="nav-link"
               routerLink="/choose-country-region"
               routerLinkActive="active">{{'components.footer.choose-your-region' | translate}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="offcanvas-footer d-flex pt-3 pb-4 justify-content-between justify-content-sm-center align-items-center">
    <a class="nav-link ms-4 ms-sm-0" gaEvent="instagram-sidebar_click"
       href="https://www.instagram.com/agendacita.mx/"
       target="_blank">
      <bi class="icon-small" name="instagram"></bi>
    </a>
    <a class="d-inline-block d-sm-none" href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">
      <button class="btn btn-warning text-uppercase fw-semibold mx-4"
              gaCategory="sign-up"
              gaEvent="sign-up-header_click"
              type="button">{{'menu-items.register' | translate}}
      </button>
    </a>
    <a class="d-inline-block d-sm-none" href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-in">
      <button class="btn btn-outline-success text-uppercase fw-semibold me-4"
              type="button">{{'menu-items.my-account' | translate}}
      </button>
    </a>
  </div>
</ng-template>

<div *ngIf="showChooseLocale"
     class="choose-locale alert alert-info bg-info bg-opacity-15 alert-dismissible d-flex align-items-center justify-content-center text-center pe-4-5 ps-2 ps-md-5 py-0 my-0"
     role="alert">
  <div>
    <a (click)="hideChooseLocale()" class="alert-link"
       routerLink="/choose-country-region">{{'components.header.choose_different_region' | translate}}</a>
    {{'components.header.or_continue_with' | translate}}
    <span class="fw-semibold text-nowrap">
        {{('locales.' + translateService.currentLang) | translate}}
    </span>
  </div>
  <button (click)="hideChooseLocale()" class="btn-close" type="button"></button>
</div>

<div [class.squeezed]="showChooseLocale"
     class="main-wrapper container-fluid d-flex flex-nowrap px-0">
  <aside class="d-none d-lg-flex flex-column flex-shrink-0 justify-content-between shadow py-4" id="sidebar">
    <div class="d-flex justify-content-center">
      <a class="nav-link" routerLink="/{{translateService.currentLang}}">
        <h2 class="mb-0">Agenda<span class="text-success">cita</span></h2>
      </a>
    </div>
    <ul class="navbar-nav px-4">
      <li class="nav-item">
        <a class="nav-link" routerLink="/{{translateService.currentLang}}/online-booking"
           routerLinkActive="active">{{'menu-items.online-booking' | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/{{translateService.currentLang}}/appointment-schedule"
           routerLinkActive="active">{{'menu-items.appointment-scheduling' | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/{{translateService.currentLang}}/sms-and-email-notification"
           routerLinkActive="active">{{'menu-items.notifications-and-reminders' | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/{{translateService.currentLang}}/client-base"
           routerLinkActive="active">{{'menu-items.client-database' | translate}}</a>
      </li>
    </ul>
    <div class="d-flex justify-content-center">
      <a class="nav-link" gaEvent="instagram-sidebar_click" href="https://www.instagram.com/agendacita.mx/"
         target="_blank">
        <bi class="icon-xs" name="instagram"></bi>
      </a>
    </div>
  </aside>

  <!-- id="content-scroller" is passed to ViewportScroller factory in AppModule -->
  <div class="d-flex flex-column flex-shrink-0 flex-grow-1 align-items-start overflow-y-scroll overflow-x-hidden"
       id="content-scroller">
    <nav class="navbar sticky-top w-100 py-3 shadow shadow-sm bg-white">
      <div class="d-flex flex-row align-items-center">
        <span (click)="openOffCanvas(offcanvasNavbar)"
              class="navbar-toggler navbar-toggler-icon d-lg-none ms-3 mx-sm-3"
              id="navbar-toggler"></span>
        <ul class="navbar-nav flex-row d-none d-sm-flex">
          <li class="nav-item">
            <a class="nav-link" routerLink="/{{translateService.currentLang}}/pricing"
               routerLinkActive="active">{{'menu-items.pricing' | translate}}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/{{translateService.currentLang}}/about"
               routerLinkActive="active">{{'menu-items.about-us' | translate}}</a>
          </li>
        </ul>
      </div>
      <div>
        <a class="nav-link navbar-logo d-lg-none" routerLink="/{{translateService.currentLang}}">
          <h4 class="my-0">Agenda<span class="text-success">cita</span></h4>
        </a>
      </div>
      <div>
        <a href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">
          <button class="btn btn-warning text-uppercase fw-semibold me-3"
                  gaCategory="sign-up"
                  gaEvent="sign-up-header_click"
                  type="button">{{'menu-items.register' | translate}}
          </button>
        </a>
        <a href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-in">
          <button class="btn btn-outline-success d-none d-sm-inline-block text-uppercase fw-semibold me-3"
                  type="button">{{'menu-items.my-account' | translate}}
          </button>
        </a>
      </div>
    </nav>

    <main class="container-fluid">
      <router-outlet></router-outlet>
    </main>

    <footer class="w-100 mt-auto d-flex flex-column p-3">
      <div>
        <div class="row">
          <div class="col-12 col-lg-4 col-xl-5 col-xxl-6 mb-4 mb-lg-0">
            <h2 class="mb-3">Agenda<span class="text-success">cita</span></h2>
            <p class="fs-5 fw-light mb-0">
              <span
                class="border-primary border-bottom border-bottom-dashed">Agendacita</span> {{'components.footer.description' | translate}}
            </p>
          </div>
          <div class="col-6 col-sm-4 col-lg-2 mb-4 mb-lg-0">
            <p class="h6">
              <strong>{{'menu-items.company' | translate}}</strong>
            </p>
            <ul class="list-unstyled text-secondary mb-0">
              <li><a class="nav-link py-1"
                     routerLink="/{{translateService.currentLang}}/pricing">{{'menu-items.pricing' | translate}}</a>
              </li>
              <!-- TODO Implement before launch? -->
              <li *ngIf="false"><a class="nav-link py-1"
                                   routerLink="/{{translateService.currentLang}}/info/distribution">{{'menu-items.for-distributors' | translate}}</a>
              </li>
            </ul>
          </div>
          <div class="col-6 col-sm-4 col-lg-3 col-xl-2 mb-4 mb-lg-0">
            <h3 class="h6">
              <strong>{{'menu-items.support' | translate}}</strong>
            </h3>
            <ul class="list-unstyled text-secondary mb-0">
              <li>
                <a class="nav-link py-1" gaEvent="email-footer_click" href="mailto:contact@agendacita.mx"
                   rel="noopener noreferrer"
                   target="_blank">contact&#64;agendacita.mx</a>
              </li>
              <li><a class="nav-link py-1"
                     routerLink="/{{translateService.currentLang}}/info/faq">{{'menu-items.knowledge-base' | translate}}</a>
              </li>
            </ul>
          </div>
          <div class="col-6 col-sm-4 col-lg-3 col-xxl-2 mb-0">
            <p class="h6">
              <strong>{{'menu-items.legal-information' | translate}}</strong>
            </p>
            <ul class="list-unstyled text-secondary mb-0 pl-lg-4">
              <!-- TODO Implement before launch? -->
              <li *ngIf="false"><a class="nav-link py-1"
                                   routerLink="/{{translateService.currentLang}}/info/terms">{{'menu-items.terms-of-use' | translate}}</a>
              </li>
              <li><a class="nav-link py-1"
                     routerLink="/{{translateService.currentLang}}/info/privacy-policy">{{'menu-items.privacy-policy' | translate}}</a>
              </li>
              <!-- TODO Implement before launch? -->
              <li *ngIf="false"><a class="nav-link py-1"
                                   routerLink="/{{translateService.currentLang}}/info/license">{{'menu-items.license-agreement' | translate}}</a>
              </li>
              <li><a class="nav-link py-1"
                     routerLink="/{{translateService.currentLang}}/info/cookie-policy">{{'menu-items.cookies-policy' | translate}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr/>
      <div [class.ps-4-25]="localeService.isUserUnderGDPR() || localeService.privacyBannerNeeded()"
           class="d-flex flex-row justify-content-between">
        <div>Agendacita 2023-{{currentYear}}</div>
        <div>
          <a (click)="hideChooseLocale()" class="nav-link text-secondary-emphasis"
             routerLink="/choose-country-region">{{'components.footer.choose-your-region' | translate}}</a>
        </div>
        <div
          class="fw-semibold text-nowrap invisible d-none d-sm-block">{{('locales.' + translateService.currentLang) | translate}}
        </div>
      </div>
    </footer>

    <div (click)="showAcceptCookiesDetails = true; showAcceptCookies = true"
         *ngIf="localeService.isUserUnderGDPR()"
         class="cookie-trigger position-absolute bottom-0 left-0 p-3 cursor-pointer">
      <mat-icon class="icon-small material-symbols-outlined">cookie</mat-icon>
    </div>

    <div (click)="showPrivacySettingsDetails = true; showPrivacySettings = true"
         *ngIf="localeService.privacyBannerNeeded() && !localeService.isUserUnderGDPR()"
         class="cookie-trigger position-absolute bottom-0 left-0 p-3 cursor-pointer">
      <bi class="icon-xs" name="shield-lock"></bi>
    </div>
  </div>

  <div #localeDropdown="ngbDropdown"
       class="locale-dropdown-wrapper d-none d-sm-block bg-white bg-opacity-10 p-3" ngbDropdown
       placement="top-end">
    <span class="fw-semibold text-nowrap pt-2" id="localeDropdown"
          ngbDropdownToggle>{{('locales.' + translateService.currentLang) | translate}}</span>
    <div aria-labelledby="localeDropdown" class="px-2 py-0" ngbDropdownMenu>
      <app-choose-country-region-widget
        (localeSelect)="localeDropdown.close()"></app-choose-country-region-widget>
    </div>
  </div>
</div>

<div *ngIf="showAcceptCookies"
     class="accept-cookies row position-fixed bottom-0 start-0 end-0 alert alert-warning bg-warning bg-opacity-95 alert-dismissible d-flex flex-row align-items-center justify-content-center gap-4 gap-md-0 text-center p-3 px-md-5 py-md-4 m-0"
     role="alert">
  <div class="information text-start px-0 py-md-4">
    <div>
      <span [innerHTML]="'components.accept-cookies.information-1' | translate"></span>&nbsp;<a
      class="alert-link"
      routerLink="/{{translateService.currentLang}}/info/privacy-policy">{{'components.accept-cookies.privacy-policy' | translate}}</a>&nbsp;<span
      [innerHTML]="'components.accept-cookies.information-2' | translate"></span>&nbsp;<a
      class="alert-link"
      routerLink="/{{translateService.currentLang}}/info/cookie-policy">{{'components.accept-cookies.cookie-policy' | translate}}</a>
      <span [innerHTML]="'components.accept-cookies.information-3' | translate"></span><br/>
    </div>
    <div *ngIf="!showAcceptCookiesDetails">
      <span [innerHTML]="'components.accept-cookies.use-buttons' | translate"></span>
    </div>

    <!-- Show granular control directly in the first layer -->
    <div *ngIf="localeService.isUserFromDenmarkOrGreece() && !showAcceptCookiesDetails"
         class="d-flex flex-wrap pt-2">
      <div class="d-flex align-items-center col-6 col-md-3">
        <div class="form-check form-switch fs-4">
          <input checked class="form-check-input" disabled role="switch" type="checkbox">
        </div>
        <div>
          <span>{{'components.accept-cookies.cookies-necessary' | translate}}</span>
        </div>
      </div>
      <div class="d-flex align-items-center col-6 col-md-3">
        <div class="form-check form-switch fs-4">
          <input [(ngModel)]="cookieConsent.functionality" class="form-check-input" role="switch" type="checkbox">
        </div>
        <div>
          <span>{{'components.accept-cookies.cookies-functionality' | translate}}</span>
        </div>
      </div>
      <div class="d-flex align-items-center col-6 col-md-3">
        <div class="form-check form-switch fs-4">
          <input [(ngModel)]="cookieConsent.performance" class="form-check-input" role="switch" type="checkbox">
        </div>
        <div>
          <span>{{'components.accept-cookies.cookies-performance' | translate}}</span>
        </div>
      </div>
      <div class="d-flex align-items-center col-6 col-md-3">
        <div class="form-check form-switch fs-4">
          <input [(ngModel)]="cookieConsent.marketing" class="form-check-input" role="switch" type="checkbox">
        </div>
        <div>
          <span>{{'components.accept-cookies.cookies-marketing' | translate}}</span>
        </div>
      </div>
    </div>

    <div *ngIf="showAcceptCookiesDetails">
      <div>
        <span [innerHTML]="'components.accept-cookies.to-deny' | translate"></span>
      </div>
      <div class="pt-3 pb-2">
        <span class="fs-5 fw-semibold">{{'components.accept-cookies.your-consent-preferences' | translate}}</span>
      </div>
      <div class="pb-2">
        <span [innerHTML]="'components.accept-cookies.information-4' | translate"></span>&nbsp;<a
        class="alert-link"
        routerLink="/{{translateService.currentLang}}/info/cookie-policy">{{'components.accept-cookies.cookie-policy' | translate}}</a><span
        [innerHTML]="'components.accept-cookies.information-5' | translate"></span>
      </div>
      <div ngbAccordion>
        <div class="col-md-8 col-lg-6 py-2 m-auto" ngbAccordionItem><!--Necessary-->
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <div class="fs-5-6 fw-semibold">
                <span>{{'components.accept-cookies.cookies-necessary' | translate}}</span>
              </div>
              <div class="cookie-description d-md-none fs-smaller text-body-secondary cursor-pointer"
                   ngbAccordionToggle>
                <span class="see-description">{{'components.accept-cookies.see-description' | translate}}</span>
                <span class="hide-description">{{'components.accept-cookies.hide-description' | translate}}</span>
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div class="cookie-description d-none d-md-block fs-smaller text-body-secondary cursor-pointer"
                   ngbAccordionToggle>
                <span class="see-description">{{'components.accept-cookies.see-description' | translate}}</span>
                <span class="hide-description">{{'components.accept-cookies.hide-description' | translate}}</span>
              </div>
              <div class="form-check form-switch fs-3 ms-md-4">
                <input checked class="form-check-input" disabled role="switch" type="checkbox">
              </div>
            </div>
          </div>
          <div ngbAccordionCollapse>
            <div class="p-0">{{'components.accept-cookies.necessary-description' | translate}}</div>
          </div>
        </div>

        <div class="col-md-8 col-lg-6 py-2 m-auto" ngbAccordionItem><!--Functionality-->
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <div class="fs-5-6 fw-semibold">
                <span>{{'components.accept-cookies.cookies-functionality' | translate}}</span>
              </div>
              <div class="cookie-description d-md-none fs-smaller text-body-secondary cursor-pointer"
                   ngbAccordionToggle>
                <span class="see-description">{{'components.accept-cookies.see-description' | translate}}</span>
                <span class="hide-description">{{'components.accept-cookies.hide-description' | translate}}</span>
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div class="cookie-description d-none d-md-block fs-smaller text-body-secondary cursor-pointer"
                   ngbAccordionToggle>
                <span class="see-description">{{'components.accept-cookies.see-description' | translate}}</span>
                <span class="hide-description">{{'components.accept-cookies.hide-description' | translate}}</span>
              </div>
              <div class="form-check form-switch fs-3 ms-md-4">
                <input [(ngModel)]="cookieConsent.functionality" class="form-check-input" role="switch" type="checkbox">
              </div>
            </div>
          </div>
          <div ngbAccordionCollapse>
            <div class="p-0">{{'components.accept-cookies.functionality-description' | translate}}</div>
          </div>
        </div>

        <div class="col-md-8 col-lg-6 py-2 m-auto" ngbAccordionItem><!--Performance-->
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <div class="fs-5-6 fw-semibold">
                <span>{{'components.accept-cookies.cookies-performance' | translate}}</span>
              </div>
              <div class="cookie-description d-md-none fs-smaller text-body-secondary cursor-pointer"
                   ngbAccordionToggle>
                <span class="see-description">{{'components.accept-cookies.see-description' | translate}}</span>
                <span class="hide-description">{{'components.accept-cookies.hide-description' | translate}}</span>
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div class="cookie-description d-none d-md-block fs-smaller text-body-secondary cursor-pointer"
                   ngbAccordionToggle>
                <span class="see-description">{{'components.accept-cookies.see-description' | translate}}</span>
                <span class="hide-description">{{'components.accept-cookies.hide-description' | translate}}</span>
              </div>
              <div class="form-check form-switch fs-3 ms-md-4">
                <input [(ngModel)]="cookieConsent.performance" class="form-check-input" role="switch" type="checkbox">
              </div>
            </div>
          </div>
          <div ngbAccordionCollapse>
            <div class="p-0">{{'components.accept-cookies.performance-description' | translate}}</div>
          </div>
        </div>

        <div class="col-md-8 col-lg-6 pt-2 m-auto" ngbAccordionItem><!--Marketing-->
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <div class="fs-5-6 fw-semibold">
                <span>{{'components.accept-cookies.cookies-marketing' | translate}}</span>
              </div>
              <div class="cookie-description d-md-none fs-smaller text-body-secondary cursor-pointer"
                   ngbAccordionToggle>
                <span class="see-description">{{'components.accept-cookies.see-description' | translate}}</span>
                <span class="hide-description">{{'components.accept-cookies.hide-description' | translate}}</span>
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div class="cookie-description d-none d-md-block fs-smaller text-body-secondary cursor-pointer"
                   ngbAccordionToggle>
                <span class="see-description">{{'components.accept-cookies.see-description' | translate}}</span>
                <span class="hide-description">{{'components.accept-cookies.hide-description' | translate}}</span>
              </div>
              <div class="form-check form-switch fs-3 ms-md-4">
                <input [(ngModel)]="cookieConsent.marketing" class="form-check-input" role="switch" type="checkbox">
              </div>
            </div>
          </div>
          <div ngbAccordionCollapse>
            <div class="p-0">{{'components.accept-cookies.marketing-description' | translate}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="buttons d-flex align-items-center justify-content-between flex-wrap gap-3 px-0">
    <button *ngIf="localeService.isUserFromItaly()"
            class="btn-close p-2"
            gaEvent="close-cookies_click"></button>
    <button (click)="showCookieDetails()"
            *ngIf="!showAcceptCookiesDetails"
            [class.text-uppercase]="!localeService.isUserFromMexico()"
            class="d-none d-md-block btn btn-warning border-warning-subtle col-md-auto px-2 px-sm-4 py-2 text-nowrap fw-semibold">{{(showAcceptCookiesDetails || localeService.isUserFromDenmarkOrGreece() ? 'components.accept-cookies.learn-more' : 'components.accept-cookies.customize') | translate}}
    </button>
    <!--Accept and Reject buttons should be equally prominent-->
    <button (click)="rejectCookies()"
            [class.text-uppercase]="!localeService.isUserFromMexico()"
            [ngClass]="{
              'btn-warning border-warning-subtle': showAcceptCookiesDetails,
              'btn-success border-success-subtle': !showAcceptCookiesDetails,
            }"
            class="btn flex-grow-1 flex-md-grow-0 px-2 px-sm-4 py-2 text-nowrap fw-semibold"
            gaEvent="reject-all-cookies_click">{{(showAcceptCookiesDetails || localeService.isUserFromDenmarkOrGreece() ? 'components.accept-cookies.reject-all' : 'components.accept-cookies.reject') | translate}}
    </button>
    <button (click)="acceptCookies()"
            [class.text-uppercase]="!localeService.isUserFromMexico()"
            [ngClass]="{
              'btn-warning border-warning-subtle': showAcceptCookiesDetails,
              'btn-success border-success-subtle': !showAcceptCookiesDetails,
            }"
            class="btn flex-grow-1 flex-md-grow-0 px-2 px-sm-4 py-2 text-nowrap fw-semibold"
            gaEvent="accept-all-cookies_click">{{(showAcceptCookiesDetails || localeService.isUserFromDenmarkOrGreece() ? 'components.accept-cookies.accept-all' : 'components.accept-cookies.accept') | translate}}
    </button>
    <button (click)="showCookieDetails()"
            *ngIf="!showAcceptCookiesDetails"
            [class.text-uppercase]="!localeService.isUserFromMexico()"
            class="d-block d-md-none btn btn-warning border-warning-subtle col-12 px-2 px-sm-4 py-2 text-nowrap fw-semibold">{{'components.accept-cookies.customize' | translate}}
    </button>
    <button (click)="saveCookieDetails()"
            *ngIf="showAcceptCookiesDetails"
            [class.text-uppercase]="!localeService.isUserFromMexico()"
            class="btn btn-success border-success-subtle col-12 col-md-auto px-2 px-sm-4 py-2 text-nowrap fw-semibold"
            gaEvent="accept-custom-cookies_click">{{'components.accept-cookies.save-and-continue' | translate}}
    </button>
  </div>
</div>

<div *ngIf="showPrivacySettings"
     [ngClass]="{
      'flex-row align-items-center': !showPrivacySettingsDetails,
      'flex-column': showPrivacySettingsDetails,
     }"
     class="accept-cookies privacy-settings position-fixed bottom-0 start-0 end-0 alert alert-warning bg-warning bg-opacity-95 alert-dismissible d-flex flex-wrap flex-md-nowrap  justify-content-between gap-3 p-3 m-0"
     role="alert">
  <div class="information px-0 py-0">
    <div>
      <span>{{'components.accept-cookies.we-use-cookies' | translate}}</span>
    </div>
    <div *ngIf="showPrivacySettingsDetails">
      <div class="pt-3 pb-2">
        <span class="fs-5 fw-semibold">{{'components.accept-cookies.us-title' | translate}}</span>
      </div>
      <div>
        <span>{{'components.accept-cookies.us-description' | translate}}</span>
      </div>
      <div class="pb-2">
        <span>{{'components.accept-cookies.learn-privacy-rights' | translate}}</span>&nbsp;<a
        class="alert-link"
        routerLink="/{{translateService.currentLang}}/info/privacy-policy">{{'components.accept-cookies.privacy-policy' | translate}}</a>.
      </div>

      <div>
        <div class="col-md-8 col-lg-6 py-2 m-auto"><!--Sale-->
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <div class="fs-5-6">
                <span>{{'components.accept-cookies.privacy-sale' | translate}}</span>
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div class="form-check form-switch fs-3 ms-md-4">
                <input [(ngModel)]="privacySettings.saleInformation" checked class="form-check-input" role="switch"
                       type="checkbox">
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-8 col-lg-6 py-2 m-auto"><!--Share-->
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <div class="fs-5-6">
                <span>{{'components.accept-cookies.privacy-share' | translate}}</span>
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div class="form-check form-switch fs-3 ms-md-4">
                <input [(ngModel)]="privacySettings.shareInformation" checked class="form-check-input" role="switch"
                       type="checkbox">
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-8 col-lg-6 py-2 m-auto"><!--Process-->
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <div class="fs-5-6">
                <span>{{'components.accept-cookies.privacy-process' | translate}}</span>
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div class="form-check form-switch fs-3 ms-md-4">
                <input [(ngModel)]="privacySettings.processInformationForAdvertising" checked class="form-check-input"
                       role="switch"
                       type="checkbox">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap flex-grow-1 gap-3 px-0">
    <button (click)="showPrivacyDetails()"
            *ngIf="!showPrivacySettingsDetails"
            class="btn btn-warning border-warning-subtle flex-grow-1 flex-md-grow-0 col-md-auto px-2 px-sm-4 py-2 text-uppercase text-nowrap fw-semibold">{{'components.accept-cookies.learn-more' | translate}}
    </button>
    <button (click)="acceptPrivacySettings()"
            [ngClass]="{
              'btn-warning border-warning-subtle': showPrivacySettingsDetails,
              'btn-success border-success-subtle': !showPrivacySettingsDetails,
            }"
            class="btn flex-grow-1 flex-md-grow-0 px-2 px-sm-4 py-2 text-uppercase text-nowrap fw-semibold"
            gaEvent="accept-all-privacy-settings_click">{{(showPrivacySettingsDetails ? 'components.accept-cookies.accept-all' : 'components.accept-cookies.accept') | translate}}
    </button>
    <button (click)="savePrivacyDetails()"
            *ngIf="showPrivacySettingsDetails"
            class="btn btn-success border-success-subtle flex-grow-1 flex-md-grow-0 col-12 col-md-auto px-2 px-sm-4 py-2 text-uppercase text-nowrap fw-semibold"
            gaEvent="accept-custom-privacy-settings_click">
      Save and continue
    </button>
  </div>
</div>
