<div class="privacy-policy">
  <section class="pt-5">
    <div class="container pt-5">
      <header class="text-center mx-auto mb-5">
        <h1 class="fw-bold mb-0">{{'components.privacy.title' | translate}}</h1>
        <hr class="w-50px mx-auto my-5">
      </header>
    </div>
  </section>
  <section class="pb-3">
    <div class="container">
      <p>{{'components.privacy.p1' | translate}}</p>
    </div>
  </section>
  <section class="pb-3">
    <div class="container">
      <h5 class="text-center pb-2">{{'components.privacy.h1' | translate}}</h5>
      <p>{{'components.privacy.p2' | translate}}</p>
      <p>{{'components.privacy.p3' | translate}}</p>
    </div>
  </section>
  <section class="pb-3">
    <div class="container">
      <h5 class="text-center pb-2">{{'components.privacy.h2' | translate}}</h5>
      <p>{{'components.privacy.p4' | translate}}</p>
    </div>
  </section>
  <section class="pb-3">
    <div class="container">
      <h5 class="text-center pb-2">{{'components.privacy.h3' | translate}}</h5>
      <p>{{'components.privacy.p5' | translate}}</p>
      <p>{{'components.privacy.p6' | translate}}</p>
      <p>{{'components.privacy.p7' | translate}}</p>
      <p>{{'components.privacy.p8' | translate}}</p>
    </div>
  </section>
  <section class="pb-3">
    <div class="container">
      <h5 class="text-center pb-2">{{'components.privacy.h4' | translate}}</h5>
      <p>{{'components.privacy.p9-1' | translate}}<a
        gaEvent="email-privacy_click"
        href="mailto:{{'components.privacy.email' | translate}}"
        rel="noopener noreferrer"
        target="_blank">{{'components.privacy.email' | translate}}</a>{{'components.privacy.p9-2' | translate}}
      </p>
      <p>{{'components.privacy.p10' | translate}}</p>
    </div>
  </section>
  <section class="pb-3">
    <div class="container">
      <h5 class="text-center pb-2">{{'components.privacy.h5' | translate}}</h5>
      <p>{{'components.privacy.p11' | translate}}</p>
      <p>{{'components.privacy.p12' | translate}}<a
        gaEvent="email-privacy_click"
        href="mailto:{{'components.privacy.email' | translate}}"
        rel="noopener noreferrer"
        target="_blank">{{'components.privacy.email' | translate}}</a>.
      </p>
    </div>
  </section>
  <section class="pb-3">
    <div class="container">
      <h5 class="text-center pb-2">{{'components.privacy.h6' | translate}}</h5>
      <p>{{'components.privacy.p13' | translate}}</p>
      <p>{{'components.privacy.p14' | translate}}<a
        routerLink="/{{translateService.currentLang}}/info/privacy-policy">{{'menu-items.privacy-policy' | translate}}</a>.
      </p>
      <p>{{'components.privacy.p15' | translate}}</p>
    </div>
  </section>
  <section class="pb-5">
    <div class="container">
      <p>{{'components.privacy.footer' | translate}}</p>
    </div>
  </section>
</div>
