import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

import {environment} from "../../environments/environment";
import {COUNTRY_CACHE_NAME} from "./locale.service";

@Injectable({
  providedIn: 'root'
})
export class ConsentService {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  public saveConsent(uuid: string, consent: object) {
    return this.httpClient.post(environment.apiUrl + '/public/consent', {
      uuid: uuid,
      consent: JSON.stringify(consent),
      country: localStorage.getItem(COUNTRY_CACHE_NAME),
    });
  }
}
