import {Component} from '@angular/core';

import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent {
  constructor(
    protected translateService: TranslateService,
  ) {
  }

}
