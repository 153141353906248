<div class="faq px-1">
  <div class="py-5">
    <h2 class="fw-bold text-center mb-4">{{'components.faq.title' | translate}}</h2>
    <div class="row">
      <div class="col-12">
        <div ngbAccordion>
          <div *ngFor="let item of faqItems" ngbAccordionItem>
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>{{item.title}}</button>
            </h2>
            <div ngbAccordionCollapse>
              <div [innerHTML]="item.description" ngbAccordionBody></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
