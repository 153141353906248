import {Routes} from "@angular/router";

import {HomeComponent} from "./home/home.component";
import {PricingComponent} from "./pricing/pricing.component";
import {ChooseCountryRegionComponent} from "./choose-country-region/choose-country-region.component";
import {AboutUsComponent} from "./about-us/about-us.component";
import {OnlineBookingComponent} from "./online-booking/online-booking.component";
import {AppointmentScheduleComponent} from "./appointment-schedule/appointment-schedule.component";
import {NotificationsComponent} from "./notifications/notifications.component";
import {ClientDatabaseComponent} from "./client-database/client-database.component";
import {PrivacyComponent} from "./info/privacy/privacy.component";
import {FaqComponent} from "./info/faq/faq.component";
import {PricingDetailComponent} from "./pricing-detail/pricing-detail.component";
import {CookiesComponent} from "./info/cookies/cookies.component";
import {MainLayoutComponent} from "./main-layout.component";

export const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {path: 'pricing', component: PricingComponent, title: 'page-titles.pricing'},
      {path: 'pricing/:businessTypeId', component: PricingDetailComponent, title: 'page-titles.pricing'},
      {path: 'online-booking', component: OnlineBookingComponent, title: 'page-titles.online-booking'},
      {
        path: 'appointment-schedule',
        component: AppointmentScheduleComponent,
        title: 'page-titles.appointment-schedule'
      },
      {
        path: 'sms-and-email-notification',
        component: NotificationsComponent,
        title: 'page-titles.sms-and-email-notification'
      },
      {path: 'client-base', component: ClientDatabaseComponent, title: 'page-titles.client-base'},
      {path: 'about', component: AboutUsComponent, title: 'page-titles.about'},
      {path: 'info/privacy-policy', component: PrivacyComponent, title: 'page-titles.info-privacy'},
      {path: 'info/cookie-policy', component: CookiesComponent, title: 'page-titles.info-cookies'},
      // {path: 'info/terms', component: TermsComponent, title: 'page-titles.info-terms'},
      {path: 'info/faq', component: FaqComponent, title: 'page-titles.info-faq'},
      {path: '', component: HomeComponent, title: 'page-titles.home'},
      // {path: 'info/license', component: LicenseComponent},
      // {path: 'info/distribution', component: DistributionComponent},
    ],
  },
  {
    path: 'choose-country-region',
    component: MainLayoutComponent,
    data: {skipRouteLocalization: true},
    children: [
      {
        path: '',
        component: ChooseCountryRegionComponent,
        title: 'page-titles.choose-country-region'
      },
    ],
  },
  /*{
    path: 'temp',
    component: NoLayoutComponent,
    children: [
      {path: 'calendar', component: CalendarComponent},
      {path: 'widget', component: WidgetComponent},
      {path: 'mobile-app', component: MobileAppComponent},
    ],
  },*/
  {path: '**', redirectTo: '/'} // has to be last
];
