import {Component} from '@angular/core';

import {TranslateService} from "@ngx-translate/core";

import {LOCALE_EN_150, LOCALE_EN_US, LOCALE_ES_ES, LOCALE_ES_MX, LOCALE_ES_US} from "../locales";

import {environment} from "../../environments/environment";

@Component({
  selector: 'app-appointment-schedule',
  templateUrl: './appointment-schedule.component.html',
  styleUrls: ['./appointment-schedule.component.scss']
})
export class AppointmentScheduleComponent {
  protected readonly LOCALE_ES_MX = LOCALE_ES_MX;
  protected readonly LOCALE_EN_US = LOCALE_EN_US;
  protected readonly LOCALE_ES_US = LOCALE_ES_US;
  protected readonly LOCALE_ES_ES = LOCALE_ES_ES;
  protected readonly LOCALE_EN_150 = LOCALE_EN_150;

  protected readonly environment = environment;

  constructor(
    protected translateService: TranslateService,
  ) {
  }
}
