import {LOCALE_EN_150, LOCALE_EN_US, LOCALE_ES_ES, LOCALE_ES_MX, LOCALE_ES_US} from "./locales";

export type PriceTier = {
  base: number,
  increment: number,
  whatsapp: number,
  sms?: number,
  minProfessionals: number,
  unlimited?: number,
  whatsappUnlimited?: number,
  smsUnlimited?: number,
};

// TODO load price tiers from backend ???

export const individualPriceTier: { [key: string]: number } = {
  [LOCALE_ES_MX]: 350,
  [LOCALE_EN_US]: 30,
  [LOCALE_ES_US]: 30,
  [LOCALE_ES_ES]: 30,
  [LOCALE_EN_150]: 30,
};

export const standardPriceTier: { [key: string]: PriceTier } = {
  [LOCALE_ES_MX]: {
    // 156 Whatsapp messages cost $1.638 USD ($30 MXN) (6 appointments for 6 days a week for 1 month, only appointment confirmation message)
    base: 700, // 2 employees
    increment: 115,
    whatsapp: 45,
    minProfessionals: 2,
  },
  [LOCALE_EN_US]: {
    // 156 Whatsapp messages cost $1.3728 USD
    base: 50, // 2 employees
    increment: 7,
    whatsapp: 2,
    minProfessionals: 2,
  },
  [LOCALE_ES_US]: {
    // 156 Whatsapp messages cost $1.3728 USD
    base: 50, // 2 employees
    increment: 7,
    whatsapp: 2,
    minProfessionals: 2,
  },
  [LOCALE_ES_ES]: {
    // 156 Whatsapp messages cost $5.7564 USD
    base: 50, // 2 employees
    increment: 7,
    whatsapp: 9,
    minProfessionals: 2,
  },
  [LOCALE_EN_150]: { // equal ES_ES
    base: 50, // 2 employees
    increment: 7,
    whatsapp: 9,
    minProfessionals: 2,
  },
};

export const premiumPriceTier: { [key: string]: PriceTier } = {
  [LOCALE_ES_MX]: {
    // 156 Whatsapp messages cost $1.638 USD ($30 MXN) (6 appointments for 6 days a week for 1 month, only appointment confirmation message)
    // 156 SMS messages cost $9.29136 USD (168 MXN)
    base: 1500, // 5 employees
    increment: 175,
    whatsapp: 45,
    sms: 250,
    unlimited: 7000, // 20 employee cost 4125
    whatsappUnlimited: 1600, // 20 employee cost 900
    smsUnlimited: 8500, // 20 employee cost 5000
    minProfessionals: 5,
  },
  [LOCALE_EN_US]: {
    // 156 Whatsapp messages cost $1.3728 USD
    // 156 SMS messages cost $1.37748 USD
    base: 100, // 5 employees
    increment: 10,
    whatsapp: 2,
    sms: 2,
    unlimited: 450, // 20 employee cost 250
    whatsappUnlimited: 70, // 20 employee cost 40
    smsUnlimited: 70, // 20 employee cost 40
    minProfessionals: 5,
  },
  [LOCALE_ES_US]: {
    // 156 Whatsapp messages cost $1.3728 USD
    // 156 SMS messages cost $1.37748 USD
    base: 100, // 5 employees
    increment: 10,
    whatsapp: 2,
    sms: 2,
    unlimited: 450, // 20 employee cost 250
    whatsappUnlimited: 70, // 20 employee cost 40
    smsUnlimited: 70, // 20 employee cost 40
    minProfessionals: 5,
  },
  [LOCALE_ES_ES]: {
    // 156 Whatsapp messages cost $5.7564 USD
    // 156 SMS messages cost $9.49572 USD
    base: 100, // 5 employees
    increment: 10,
    whatsapp: 9,
    sms: 15,
    unlimited: 450, // 20 employee cost 250
    whatsappUnlimited: 310, // 20 employee cost 180
    smsUnlimited: 520, // 20 employee cost 300
    minProfessionals: 5,
  },
  [LOCALE_EN_150]: { // equal ES_ES
    base: 100, // 5 employees
    increment: 10,
    whatsapp: 9,
    sms: 15,
    unlimited: 450, // 20 employee cost 250
    whatsappUnlimited: 310, // 20 employee cost 180
    smsUnlimited: 520, // 20 employee cost 300
    minProfessionals: 5,
  },
};

export const premiumPlusPriceTier: { [key: string]: PriceTier } = {
  // TODO actualize prices on tier launch
  [LOCALE_ES_MX]: {
    base: 2400,
    increment: 120,
    whatsapp: 45,
    sms: 250,
    unlimited: 4800,
    minProfessionals: 15,
  },
  [LOCALE_EN_US]: {
    base: 200,
    increment: 10,
    whatsapp: 2,
    sms: 2,
    unlimited: 400,
    minProfessionals: 15,
  },
  [LOCALE_ES_US]: {
    base: 200,
    increment: 10,
    whatsapp: 2,
    sms: 2,
    unlimited: 400,
    minProfessionals: 15,
  },
  [LOCALE_ES_ES]: {
    base: 200,
    increment: 10,
    whatsapp: 9,
    sms: 15,
    unlimited: 400,
    minProfessionals: 15,
  },
  [LOCALE_EN_150]: {
    base: 200,
    increment: 10,
    whatsapp: 9,
    sms: 15,
    unlimited: 400,
    minProfessionals: 15,
  },
};
