<div class="cookies">
  <section class="pt-5">
    <div class="container pt-5">
      <header class="text-center mx-auto mb-5">
        <h1 class="fw-bold mb-0">{{'components.cookies.title' | translate}}</h1>
        <hr class="w-50px mx-auto my-5">
      </header>
    </div>
  </section>
  <section class="pb-3">
    <div class="container">
      <h5 class="text-center pb-2">{{'components.cookies.h1' | translate}}</h5>
      <p>{{'components.cookies.p1' | translate}}</p>
    </div>
  </section>
  <section class="pb-3">
    <div class="container">
      <h5 class="text-center pb-2">{{'components.cookies.h2' | translate}}</h5>
      <p>{{'components.cookies.p2' | translate}}</p>
    </div>
  </section>
  <section class="pb-3">
    <div class="container">
      <h5 class="text-center pb-2">{{'components.cookies.h3' | translate}}</h5>
      <p>{{'components.cookies.p3' | translate}}</p>
    </div>
  </section>
  <section class="pb-3">
    <div class="container">
      <h5 class="text-center pb-2">{{'components.cookies.h4' | translate}}</h5>
      <p>{{'components.cookies.p4' | translate}}</p>
      <p>{{'components.cookies.p5' | translate}}<a
        gaEvent="email-cookies_click"
        href="mailto:{{'components.cookies.email' | translate}}"
        rel="noopener noreferrer"
        target="_blank">{{'components.cookies.email' | translate}}</a>.
      </p>
    </div>
  </section>
  <section class="pb-5">
    <div class="container">
      <p>{{'components.cookies.footer' | translate}}</p>
    </div>
  </section>
</div>
