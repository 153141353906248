<div class="appointment-schedule pt-5 pt-md-0">
  <section class="first-section d-md-flex align-items-center">
    <div class="row align-content-center">
      <div [ngClass]="{
              'col-10 col-sm-9 col-md-7 col-lg-8 col-xl-7': translateService.currentLang === LOCALE_ES_MX || translateService.currentLang === LOCALE_ES_US || translateService.currentLang === LOCALE_ES_ES,
              'col-9 col-sm-8 col-md-6 col-lg-7 col-xl-6': translateService.currentLang === LOCALE_EN_US || translateService.currentLang === LOCALE_EN_150,
           }"
           class="align-items-center justify-content-center">
        <h1 class="fw-bold">{{'components.appointment-schedule.title' | translate}}</h1>
        <p
          class="my-5 my-lg-4 me-md-3 text-left">{{'components.appointment-schedule.title-description' | translate}}</p>
        <a class="w-100 w-sm-50 btn btn-warning py-2 text-uppercase fw-semibold"
           gaCategory="sign-up" gaEvent="sign-up-appointment-schedule_click"
           href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.start-free-trial' | translate}}</a>
        <div class="mt-4 text-left">
          <a class="link-underline link-underline-opacity-25"
             gaCategory="sign-up" gaEvent="sign-up-appointment-schedule_click"
             href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.7-days-free-trial' | translate}}</a><br/>
          <span class="text-secondary">{{'components.common.no-credit-card-required' | translate}}
            <br/>{{'components.common.free-system-setup' | translate}}</span>
        </div>
      </div>
      <div [ngClass]="{
              'col-lg-4 col-xl-5': translateService.currentLang === LOCALE_ES_MX || translateService.currentLang === LOCALE_ES_US || translateService.currentLang === LOCALE_ES_ES,
              'col-lg-5': translateService.currentLang === LOCALE_EN_US || translateService.currentLang === 'en-150',
           }"
           class="col-12 col-md-3 position-illust">
        <figure>
          <img alt="Image Description" class="hero-img position-illust pb-md-5"
               src="/assets/img/schedule-1-2-{{translateService.currentLang.split('-')[0]}}.png"/>
        </figure>
      </div>
    </div>
  </section>

  <section class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <div class="position-relative top-50 translate-middle-y">
            <h3 class="fw-bold">{{'components.appointment-schedule.unified-workplace-title' | translate}}</h3>
            <p
              class="mb-md-3 mb-sm-5 mb-5">{{'components.appointment-schedule.unified-workplace-description1' | translate}}</p>
            <img alt="Image Description" class="d-block d-md-none pl-md-4 pb-5"
                 src="/assets/img/schedule-3-{{translateService.currentLang.split('-')[0]}}.png">
            <p>{{'components.appointment-schedule.unified-workplace-description2' | translate}}</p>
            <p>{{'components.appointment-schedule.unified-workplace-description3' | translate}}</p>
          </div>
        </div>
        <div class="col-md-7">
          <img class="shadow w-100"
               src="/assets/img/electronic-schedule-1-{{translateService.currentLang.split('-')[0]}}.jpg" type="">
        </div>
      </div>
    </div>
  </section>

  <section class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <img alt="Image Description" class="d-none d-md-block"
               src="/assets/img/electronic-schedule-2-{{translateService.currentLang.split('-')[0]}}.jpg">
        </div>
        <div class="col-md-5">
          <div class="position-relative top-50 translate-middle-y">
            <h3 class="fw-bold">{{'components.appointment-schedule.up-to-date-schedule-title' | translate}}</h3>
            <img alt="Image Description" class="pr-md-4 d-block d-md-none"
                 src="/assets/img/electronic-schedule-2-{{translateService.currentLang.split('-')[0]}}.jpg">
            <p>{{'components.appointment-schedule.up-to-date-schedule-description' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-md-12 text-center pt-5">
          <a class="btn btn-warning px-4 py-2 text-uppercase fw-semibold"
             gaCategory="sign-up" gaEvent="sign-up-appointment-schedule_click"
             href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.start-free-trial' | translate}}</a>
        </div>
      </div>
    </div>
  </section>

  <section class="py-5">
    <div class="container overflow-visible z-3">
      <div class="row">
        <div class="col-12 text-center mb-5">
          <h2 class="fw-bold">{{'components.appointment-schedule.free-up-your-time-title' | translate}}</h2>
        </div>
      </div>
      <div class="row justify-content-lg-center">
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-5">
          <div class="rounded bg-primary text-white">
            <div class="p-5">
              <div class="mb-4">
                <div class="btn btn-warning rounded-circle text-white p-2-5 cursor-default">
                  <bi class="icon-large" name="alarm"></bi>
                </div>
              </div>
              <h3
                class="fw-bold h5">{{'components.appointment-schedule.free-up-your-time-item1-title' | translate}}</h3>
              <p
                class="mb-4">{{'components.appointment-schedule.free-up-your-time-item1-description' | translate}}</p>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-0 mt-5">
          <div class="rounded bg-light">
            <div class="p-5">
              <div class="mb-4">
                <div class="btn btn-warning rounded-circle text-white p-2-5 cursor-default">
                  <bi class="icon-large" name="cloud"></bi>
                </div>
              </div>
              <h3
                class="fw-bold h5">{{'components.appointment-schedule.free-up-your-time-item2-title' | translate}}</h3>
              <p
                class="mb-4">{{'components.appointment-schedule.free-up-your-time-item2-description' | translate}}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-4 mb-0 mt-5">
          <div class="rounded bg-light">
            <div class="p-5">
              <div class="mb-4">
                <div class="btn btn-warning rounded-circle text-white p-2-5 cursor-default">
                  <bi class="icon-large" name="phone"></bi>
                </div>
              </div>
              <h3
                class="fw-bold h5">{{'components.appointment-schedule.free-up-your-time-item3-title' | translate}}</h3>
              <p
                class="mb-4">{{'components.appointment-schedule.free-up-your-time-item3-description' | translate}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-md-12 text-center pt-5">
          <a class="btn btn-warning px-4 py-2 text-uppercase fw-semibold"
             gaCategory="sign-up" gaEvent="sign-up-appointment-schedule_click"
             href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.start-free-trial' | translate}}</a>
        </div>
      </div>
    </div>
  </section>

  <section class="text-center py-5">
    <div class="container">
      <div class="row mb-5">
        <div class="col-12 text-center">
          <h2
            class="fw-bold">{{'components.appointment-schedule.appointment-calendar-integrated-title' | translate}}</h2>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-4">
          <div class="px-3 mb-5 mb-lg-0">
            <div class="btn btn-warning rounded-circle text-white p-3 cursor-default">
              <bi class="icon-large" name="app-indicator"></bi>
            </div>
            <h3
              class="fw-bold h5 mb-3 mt-4">{{'components.appointment-schedule.appointment-calendar-integrated-item1-title' | translate}}</h3>
            <p>{{'components.appointment-schedule.appointment-calendar-integrated-item1-description' | translate}}</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="px-3">
            <div class="btn btn-warning rounded-circle text-white p-3 cursor-default">
              <bi class="icon-large" name="list-check"></bi>
            </div>
            <h3
              class="fw-bold h5 mb-3 mt-4">{{'components.appointment-schedule.appointment-calendar-integrated-item2-title' | translate}}</h3>
            <p>{{'components.appointment-schedule.appointment-calendar-integrated-item2-description' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center pt-5">
          <a class="btn btn-warning px-4 py-2 text-uppercase fw-semibold"
             gaCategory="sign-up" gaEvent="sign-up-appointment-schedule_click"
             href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.start-free-trial' | translate}}</a>
        </div>
      </div>
    </div>
  </section>

  <section class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h3 class="fw-bold mb-4">{{'components.common.join-businesses1' | translate}}
            <br>{{'components.common.join-businesses2' | translate}}</h3>
          <p class="text-center mt-4">
            <a class="btn btn-warning px-4 py-2 text-uppercase fw-semibold"
               gaCategory="sign-up" gaEvent="sign-up-appointment-schedule_click"
               href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.try-for-free' | translate}}</a>
          </p>
        </div>
      </div>
    </div>
  </section>
</div>
