import {Component} from '@angular/core';

import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {AbstractSubscriber} from "../../services/abstract-subscriber";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent extends AbstractSubscriber {
  protected faqItems: any = [];

  constructor(
    protected translateService: TranslateService,
  ) {
    super();

    this.fillFaqArray();

    this.track(
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.fillFaqArray();
      })
    );
  }

  private fillFaqArray() {
    this.faqItems = this.translateService.instant('components.faq.items');
  }
}
